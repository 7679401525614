<template>
  <section id="PageGenreListMovie" class="col-12 wrapper-text">
    <div class="card p-3">
      <div class="card-header bg-color-primary-2 text-white text-center">
        تلوزیون خانگی با ژانر {{ genreName }}
      </div>
      <div class="card-body">
        <div
            class="row"
            v-if="this.$store.state.getMovie.status.ListTVGenreLoading"
        >
          <div
              class="col-sm-6 col-md-3 col-lg-3"
              v-for="movie in ListTVGenre"
              :key="movie.id"
          >
            <CardViewTv
                :movie="movie"
                :size="imageSizeMovie('w500')"
            ></CardViewTv>
          </div>
        </div>
        <Spinner v-else/>
      </div>
    </div>
  </section>
  <div class="col-12">
    <ThePagination
        :currentPage="this.$store.state.getMovie.pages.current"
        :totalPage="
        this.$store.state.getMovie.pages.totalPage < 500
          ? this.$store.state.getMovie.pages.totalPage
          : 500
      "
        @pageChanged="showPageChanged"
    ></ThePagination>
    <p
        v-if="this.$store.state.getMovie.pages.current === 500"
        class="text-center text-danger mb-0"
    >
      حداکثر اطلاعات ارائه شده توسط API در حالت "درخواست بر اساس شماره صفحه" ۵۰۰
      صفحه میباشد.
    </p>
  </div>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageGenreListTv",
  components: {
    'CardViewTv': defineAsyncComponent(() =>
        import('@/components/cardView/cardViewTv')
    ),
    'ThePagination': defineAsyncComponent(() =>
        import('@/components/section/ThePagination')
    ),
    'Spinner': defineAsyncComponent(() =>
        import('@/components/template/spinner')
    )
  },
  computed: {
    genreName() {
      return this.translatePersian(this.$route.query.genreName);
    },
    ListTVGenre() {
      return this.$store.state.getMovie.ListTVGenre;
    },
  },
  methods: {
    getList(page) {
      let genre_id = this.$route.params.id;
      let query = `discover/tv?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&sort_by=popularity.desc&page=${page}&with_genres=${genre_id}`;
      this.$store.dispatch("getMovie/fetchDataPagination", {
        page: page,
        query: query,
        setName: "setListTvGenre",
      });
    },
  },
  mounted() {
    this.getList(1);
  },
};
</script>

<style scoped></style>
